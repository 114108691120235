import type { TimeFilterConfiguration } from '@grandvisionhq/appointments'

const config: TimeFilterConfiguration = [
  {
    name: 'option1',
    from: '09:00',
    until: '11:59',
  },
  {
    name: 'option2',
    from: '12:00',
    until: '19:59',
  },
  {
    name: 'option3',
    from: '20:00',
    until: '23:59',
  },
]

export const getTimeFiltersConfig = () => config
