import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.PT,
  locale: Locales['pt-PT'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    long: 'EEEE, d MMMM, y',
    midsize: 'dd MMMM yyyy',
    short: 'dd/MM/yyyy',
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'pt-PT'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'pt-PT': INTL_DEFAULTS,
}
