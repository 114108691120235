import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    'c3c9fbc4-5b86-4cbf-a77d-31d014f75639': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
    '9cba14a1-953f-4743-9cd2-22255820f848': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
    '9958cc57-9ea5-4b2c-9abe-32927732d6b3': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '5 a 7',
  },
})
