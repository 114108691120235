import type { FormatterMap, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
} from '@grandvisionhq/www-next/formatters'

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createSimpleFormatter('marcar-consulta-visual-gratuita'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createMyAccountFormatters(),
  ...createAuthFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { shipping: 'envio', overview: 'resumo', payment: 'pagamento' },
  }),
  ...productDetailFormatters({
    accessories: 'acessorios',
    'contact-lenses': 'lentes-de-contacto',
    frames: 'armacoes-oculos-graduados',
    'ready-readers': 'armacoes-oculos-graduados/oculos-de-leitura',
    solutions: 'lentes-de-contacto/liquidos-de-limpeza',
    sunglasses: 'oculos-de-sol',
  }),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/envio', 'checkout.shipping'],
  ['/checkout/resumo', 'checkout.overview'],
  ['/checkout/pagamento', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/marcar-consulta-visual-gratuita/(.*)?', 'appointments.book'],
  ['/cancelar-marcacao-de-consulta', 'appointments.cancel'],
  ['/reagendar-marcacao-de-consulta', 'appointments.reschedule'],
]
